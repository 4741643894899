<template>
  <div class="recomand-page">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :head-height="36"
      :success-duration="1300"
    >
      <!-- <template #pulling="props">
        <div v-if="props">
          <span>释放可刷新</span>
        </div>
      </template> -->
      <template #loading>
        <div class="refresh-box">
          <img
            class="loading-iocn"
            src="../../../../assets/img/quickSend/loading.svg"
            alt="加载"
          />
          <span>最新记录获取中</span>
        </div>
      </template>
      <template #success>
        <div class="animate-refresh">
          <span>最新时间</span>
          &nbsp;
          <span>{{ nowDate() }}</span>
        </div>
      </template>
      <div v-if="showRecomand" class="recommand-list">
        <RecommandItem
          v-for="(item, index) in list"
          :recommandItem="item"
          :customer_userid="this.params.customer_id"
          :chat_id="this.params.chat_id"
          :key="index"
        ></RecommandItem>
      </div>
      <div v-else class="no-data-page">
        <div class="no-data-content">
          <img src="@/assets/img/quickSend/talk_nodata.svg" alt="暂无数据" />
          <p v-if="hasAuthority === 0" class="no-data-hint">
            <span>暂未开通成员会话存档，</span>
            <br />
            <span>请联系企业微信管理员开通会话存档权限</span>
          </p>
          <p
            class="no-data-hint"
            v-else-if="hasAuthority === 1 && list.length == 0"
          >
            聊天内容中暂无匹配关键词的推荐回复
          </p>
          <p v-else-if="hasAuthority === -1" class="no-data-hint">
            <span>{{ rejectTime }}</span>
            <br />
            <span>客户不同意存档会话内容</span>
          </p>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { PullRefresh, Image as VanImage } from 'vant'
import { getRecommandList } from '../../../../service/quickSend.service'
import RecommandItem from '../../../../components/quickSend/RecommandItem.vue'
import moment from 'moment'
import {
  getCurExternalChat,
  getCurExternalContact
} from '../../../../utils/nwechat'
import { recommandList } from './test'
export default {
  name: 'recomandReview',
  components: {
    [VanImage.name]: VanImage,
    [PullRefresh.name]: PullRefresh,
    RecommandItem: RecommandItem
  },
  computed: {
    // 是否展示列表
    showRecomand() {
      if (this.list.length > 0 || this.refreshing) {
        return true
      }
      return false
    }
  },
  data() {
    return {
      params: {
        customer_id: '',
        chat_id: ''
      },
      list: [],
      refreshing: false,
      hasAuthority: 0, // -1: 客户不同意， 0：没有开通， 1： 有权限
      finished: false,
      rejectTime: '',
      requestStatus: null,
      pollingIndex: 0
      // nodataText: '暂未开通成员会话存档，',
      // nodataHint: '请联系企业微信管理员开通会话存档权限'
    }
  },

  created() {
    console.log('触发初始creat')
    this.init()
    // this.list = recommandList.data
    // console.log('获取推荐列表', recommandList.data)
  },
  beforeDestroy() {
    clearInterval(this.requestStatus)
  },
  methods: {
    // 页面初始化
    async init() {
      // 轮询请求客户或者群id
      this.requestStatus = setInterval(() => {
        if (
          this.params.customer_id ||
          this.params.chat_id ||
          this.pollingIndex > 5
        ) {
          this.getList()
          clearInterval(this.requestStatus)
          return
        }
        // console.log('现在是第', this.pollingIndex)
        this.pollingIndex++
        getCurExternalContact()
          .then((res) => {
            this.params.customer_id = res
          })
          .catch(() => {
            this.params.customer_id = ''
          })
        getCurExternalChat()
          .then((res) => {
            this.params.chat_id = res
          })
          .catch(() => {
            this.params.chat_id = ''
          })
      }, 300)
    },
    // 获取id 方式
    // getIdMethods() {

    // },

    // 刷新条时间
    nowDate() {
      return moment(new Date()).format('HH:mm:ss')
    },

    // 获取推荐对话列表
    async getList() {
      console.log('列表参数', this.params)
      if (!this.params.customer_id && !this.params.chat_id) {
        return
      }
      try {
        let { data, code } = await getRecommandList(this.params)
        console.log(code, '状态码')
        if (code == 200) {
          this.hasAuthority = 1
          this.list = data || []
          console.log('推荐回复', data)
        } else if (code == 42025) {
          this.hasAuthority = 0
        } else if (code == 42029) {
          this.hasAuthority = -1
          let dataTime = data.status_change_time * 1000 || new Date()
          this.rejectTime = moment(dataTime).format('YYYY-MM-DD HH:mm:ss')
        }
      } catch (e) {
        console.log(e)
        this.finished = true
      } finally {
        return Promise.resolve(true)
      }
    },

    // 刷新页面
    async onRefresh() {
      this.refreshing = true
      await this.getList()
      this.refreshing = false
    }
  }
}
</script>
<style lang="less" scoped>
.recomand-page {
  height: calc(100vh - 66px);
  background-color: #f5f5f5;
  overflow-y: scroll;
  .recommand-list {
    padding-top: 8px;
  }
}

// .no-data-page {
//   margin-top: 1px;
//   height: calc(100vh - 72px);
//   background-color: #f5f5f5;
//   display: flex;
//   justify-content: center;
//   // padding-top: 200px;
//   align-items: center;
//   .no-data-content {
//     margin-top: -65px;
//     img {
//       display: block;
//       width: 110px;
//       height: 118px;
//       margin: 0 auto;
//     }
//     .no-data-hint {
//       margin-top: 13px;
//       font-size: 14px;
//       line-height: 20px;
//       color: #909399;
//       text-align: center;
//     }
//   }
// }
</style>
