<template>
  <div class="recommand-item-box">
    <div class="recommand-item" @click.stop="goDetail">
      <div class="share-icon-box" @click.stop="onSend(false)">
        <!-- <div class="cut-panel"></div> -->
        <span class="share-icon"></span>
      </div>
      <div class="review-icon-box" @click.stop="onSend(true)">
        <span class="share-icon"></span>
      </div>
      <div class="send-item-content">
        {{ sendItem.content }}
      </div>
      <div
        v-if="mediaShowList.length"
        :class="['media-box', sendItem.content ? 'mt12' : '']"
      >
        <div class="media-item" v-for="mediaItem in mediaShowList">
          <van-image
            v-if="mediaItem.type !== 'VIDEO'"
            width="48px"
            height="48px"
            fit="fill"
            :src="
              showImgCover(
                mediaItem.file_url,
                mediaItem.type,
                mediaItem.file_suffix
              )
            "
          />
          <video
            class="cover-img"
            v-else
            fit="fill"
            :src="mediaItem.file_url"
          ></video>
          <img
            v-if="['VIDEO', 'MINIPROGRAM', 'LINK'].includes(mediaItem.type)"
            class="play-icon"
            :src="require('@/assets/img/quickSend/' + mediaItem.type + '.svg')"
            alt="paly"
          />
        </div>
        <div v-if="maxMedia" class="over-box">
          ...共
          <span class="txt-blue">{{ mediaList.length }}</span>
          个
        </div>
      </div>
    </div>
    <span class="keyword-box" v-if="keyWords.length !== 0">
      关键词：{{ keyWordsFormat() }}
      <!-- 关键词：榴莲、活动、榴莲、活动、榴莲、活动、榴莲、活,加上考虑东方季道开始了飞机上看到房价水电费第三方士大夫 -->
    </span>
  </div>
</template>

<script>
import { Image as VanImage } from 'vant'
import { speechList, detail } from '../../views/tool/marketing/tabPage/test'
import { sendChatMessageAllType } from '@/utils/nwechat'
import { getKnowledgeDetail, setBackSend } from '@/service/quickSend.service'
import { formatParamsDistribution } from '@/utils/index.js'
// import { backPersonalSend, setBackSend } from '../../service/quickSend.service'
export default {
  components: {
    [VanImage.name]: VanImage
  },
  name: 'RecommandSendItem',
  props: {
    sendItem: {
      // 主体数据
      type: Object,
      default() {
        return {}
      }
    },
    mediaList: {
      // 媒体数据
      type: Array,
      default() {
        return []
      }
    },
    limitNum: {
      // 限制媒体数量
      type: Number,
      default() {
        return 3
      }
    },
    customer_userid: {
      // 外部id(客户id)
      type: String,
      default() {
        return ''
      }
    },
    chat_id: {
      ype: String,
      default() {
        return ''
      }
    },
    member_userid: {
      // 成员id
      type: String,
      default() {
        return ''
      }
    },
    keyWords: {
      type: Array,
      default() {
        return []
      }
    },
    sale_script_id: {
      type: String,
      default() {
        return ''
      }
    },
    talkItem: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      // 组件处于第几个
      type: Number,
      default() {
        return -1
      }
    }
  },
  data() {
    return {
      maxMedia: false,
      taskStatus: false,
      mediaShowList: [],
      endTime: 0,
      share_data_id: ''
    }
  },
  created() {
    // console.log('测试数据', this.keyWords)
    this.init()
  },
  methods: {
    init() {
      this.limitMadie()
    },
    // 限制媒体数量
    limitMadie() {
      // 深拷贝
      this.mediaShowList = JSON.parse(JSON.stringify(this.mediaList))
      if (this.mediaShowList.length > this.limitNum) {
        this.maxMedia = true
        this.mediaShowList.splice(
          this.limitNum,
          this.mediaShowList.length - this.limitNum
        )
      } else {
        this.maxMedia = false
      }
    },

    // 前往详情页
    goDetail() {
      let params = JSON.stringify(this.talkItem)
      let keyArr = JSON.stringify(this.sendItem.keyword_arr)
      sessionStorage.setItem('recomandDetail', params)
      sessionStorage.setItem('recomandDetailKey', keyArr)
      this.$router.push({
        path: '/recommand-detail',
        query: {
          sale_script_id: this.sendItem.sale_script_id,
          chat_id: this.chat_id,
          customer_userid: this.customer_userid
        }
      })
    },
    //封面图片过滤
    showImgCover(url, type, file_suffix) {
      if (!url && type == 'LINK') {
        return require('@/assets/img/suggestion/default_2x.svg')
      }
      if (!url) {
        return ''
      }
      if (type == 'FILE' && ['doc', 'docx'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/word.svg')
      } else if (type == 'FILE' && ['pdf'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/pdf.svg')
      } else if (type == 'FILE' && ['xlsx', 'xls'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/excel.svg')
      } else if (type == 'FILE' && ['pptx', 'ppt'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/ppt.svg')
      }
      let baseUrl = url.indexOf('http') === -1 ? 'https//' : ''
      return baseUrl + url
    },

    // 关键词展示
    keyWordsFormat() {
      let arr = []
      this.sendItem.keyword_arr.forEach((item) => {
        // console.log('关键词：', item, item.keyword)
        arr.push(item.keyword)
      })
      return arr.join('、')
    },
    // 发送前的埋点回调
    async beforeSend() {
      let { data, code } = await sendMonitor({
        payable_id: this.params.sale_script_id,
        type: 'reply',
        user_id: this.customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },

    // 点击发送
    async onSend(isReview) {
      // 如果发送给个人
      if (this.customer_userid) {
        await this.beforeSend()
      }
      try {
        let { data } = await getKnowledgeDetail({
          sale_script_id: this.sendItem.sale_script_id
        })
        let mediaList = data.content_arr || []
        // let mediaList = JSON.parse(JSON.stringify(detail.data.content_arr))
        // 回复点击判断第一条是否文字内容
        if (isReview) {
          if (mediaList[0].type == 'TEXT') {
            let name = this.talkItem.name
            let reviewContent = `|@${name}: \n|${this.talkItem.content}\n ---------- \n${mediaList[0].content}`
            mediaList[0].content = reviewContent
          } else {
            // 第一条不是文字的话，添加一个回复头
            let obj = {
              type: 'TEXT',
              content: `|@${this.talkItem.name}: \n|${this.talkItem.content}\n----------`
            }
            mediaList.unshift(obj)
          }
        }

        for (let i = 0; i < mediaList.length; i++) {
          let type = await this.formatType(mediaList[i].type)
          let params = await this.formatSendParams(
            type,
            mediaList[i],
            this.share_data_id
          )
          console.log(3)
          let { sendStatus } = await sendChatMessageAllType(type, params, false)
          // console.log('发送状态：-----------：', sendStatus)
          this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
        }

        if (this.taskStatus) {
          let userStorage = JSON.parse(localStorage.getItem('vuex'))
          let person = userStorage.user.info.qw_name

          window._paq.push([
            'trackEvent',
            '知识库行为',
            '发送话术',
            `${person}发送了${this.sendItem.sale_script_id}，类型是推荐回复`,
            1
          ])
          console.log('md统计1')

          await this.onSended()
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 发送之后进行回调
    async onSended() {
      let type = 'customer_receive_member_reply'
      let field = 'external_contact'
      if (this.chat_id) {
        type = 'customer_touch_member_reply_group'
        field = 'chat_id'
      }

      await setBackSend({
        sale_script_id: this.sendItem.sale_script_id,
        event_key: type,
        field: field,
        field_value: this.customer_userid || this.chat_id
      })

      // 发送完成后关闭当前窗口
      // window.location.href = 'about:blank'
      // window.close()
      wx.closeWindow()
      return Promise.resolve(true)
    },

    // // 点击根据聊天消息回复
    // async replayMessage() {
    //   try {
    //     // let { data } = await getKnowledgeDetail({
    //     //   sale_script_id: this.sendItem.sale_script_id
    //     // })
    //     // mediaList = data.content_arr || []
    //     let mediaList = JSON.parse(JSON.stringify(this.mediaList))

    //     // 判断第一条是否文字内容
    //     if (mediaList[0].type == 'TEXT') {
    //       let name = this.talkItem.name
    //       let reviewContent = `|@${name}: \n|${this.talkItem.content}\n ---------- \n${mediaList[0].content}`
    //       mediaList[0].content = reviewContent
    //     } else {
    //       // 第一条不是文字的话，添加一个回复头
    //       let obj = {
    //         type: 'TEXT',
    //         content: `|@${this.talkItem.name}: \n|${this.talkItem.content}\n----------`
    //       }
    //       mediaList.push(obj)
    //     }
    //     // console.log('确定第一个元素', mediaList[0])
    //     for (let i = 0; i < mediaList.length; i++) {
    //       let type = await this.formatType(mediaList[i].type)
    //       let params = await this.formatSendParams(type, mediaList[i])
    //       // console.log('发送参数', type, params)
    //       console.log('3', type, params)
    //       let { sendStatus } = await sendChatMessageAllType(type, params, false)
    //       // console.log('发送状态：-----------：', sendStatus)
    //       this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },

    // 分享(发送)参数配置
    async formatSendParams(type, item, share_data_id) {
      // console.log('参数', type, item)
      // 缺少http头部，补充
      if (item.file_url && item.file_url.indexOf('http') === -1) {
        item.file_url = 'https://' + item.file_url
      }

      let params = {}
      if (['image', 'video'].includes(type)) {
        params[type] = {
          mediaid: item.media_id
        }
      } else if (type == 'news') {
        // 普通h5
        if (item.type != 'FILE') {
          let imgUrl = item.file_url
            ? item.file_url
            : 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
          params[type] = {
            link: item.qw_link_url, //H5消息页面url 必填
            title: item.qw_link_title, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imgUrl //H5消息封面图片URL
          }
        } else {
          // 文件类型附件，当做h5发出去
          // 环境不同需要更换
          let baseURL = location.origin + '/preview-page?file='
          let imageUrl = this.getFileCover(item)
          params[type] = {
            link: baseURL + item.file_url + `&type=${item.file_suffix}`, //H5消息页面url 必填
            title: item.file_name, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imageUrl //H5消息封面图片URL
          }
        }
      } else if (type == 'miniprogram') {
        let path = await formatParamsDistribution(
          item.qw_miniprogram_page,
          this.customer_userid,
          this.chat_id,
          'recommand'
        )
        // 如果存在埋点id加入埋点id
        if (share_data_id) path = `${path}&share_data_id=${share_data_id}`
        params[type] = {
          appid: item.qw_miniprogram_appid, //小程序的appid
          title: item.qw_miniprogram_title, //小程序消息的title
          imgUrl: item.file_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page: path
        }
      } else if (type == 'text' && item.content) {
        // params.content = item.content
        params[type] = {
          content: item.content
        }
      } else {
        return ''
      }
      // console.log(2, params)
      return Promise.resolve(params)
    },

    // 处理小程序路径加上分配所需参数
    // formatParamsDistribution(path) {
    //   let obj = {
    //     member_id: localStorage.getItem('member_id') || '',
    //     customer_id: this.customer_userid || '',
    //     chart_id: this.chat_id || '',
    //     source: 'recommand'
    //   }
    //   let tempPath = path
    //   for (let key in obj) {
    //     if (obj[key]) {
    //       tempPath = `${tempPath}&${key}=` + obj[key]
    //     }
    //   }
    //   return Promise.resolve(tempPath)
    // },

    // 判断wechat文件类型
    formatType(medisType) {
      let type = ''
      switch (medisType) {
        case 'TEXT':
          type = 'text'
          break
        case 'IMAGE':
          type = 'image'
          break
        case 'LINK':
          type = 'news'
          break
        case 'VIDEO':
          type = 'video'
          break
        case 'MINIPROGRAM':
          type = 'miniprogram'
          break
        case 'FILE':
          type = 'news'
          break
        default:
          type = 'text'
          break
      }
      // console.log(1, type)
      return Promise.resolve(type)
    },

    // 发送时获取图片封面
    getFileCover(item) {
      if (['doc', 'docx'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
      } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
      } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
      } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
      } else {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.recommand-item {
  padding: 14px 12px;
  // border: 1px solid #1773fa;
  background-color: #cce4fc;
  border-radius: 5px;
  margin-top: 20px;
  position: relative;

  &:before {
    width: 0;
    height: 6px;
    content: '';
    border: 6px solid #cce4fc;
    position: absolute;
    border-color: transparent transparent transparent #cce4fc;
    top: 10px;
    right: -11px;
  }

  .review-icon-box {
    position: absolute;
    top: -13px;
    right: 58px;
    width: 26px;
    height: 26px;
    // background-color: #fff;
    // border: 1px solid #1773fa;
    // border-radius: 99px;
    z-index: 20;
    .share-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      background: url('../../assets/img/quickSend/review.svg') no-repeat;
      background-size: 100% 100%;
      // border-radius: 12px;
      z-index: 23;
      cursor: pointer;
    }
  }

  .share-icon-box {
    position: absolute;
    top: -13px;
    right: 17px;
    width: 26px;
    height: 26px;
    // background-color: #fff;
    // border: 1px solid #1773fa;
    border-radius: 13px;
    z-index: 20;
    .share-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 24px;
      background: url('../../assets/img/suggestion/share_icon.svg') no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      z-index: 23;
      cursor: pointer;
    }
    .cut-panel {
      position: absolute;
      top: -4px;
      right: -5px;
      background-color: #f5f5f5;
      width: 50px;
      height: 15px;
      z-index: 22;
    }
  }

  .send-item-content {
    line-height: 18px;
    font-size: 12px;
    color: #4a4a4a;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .media-box {
    display: flex;
    // flex-wrap: wrap;
    // max-height: ;
    // margin-top: 12px;
    .media-item {
      width: 48px;
      height: 48px;
      border-radius: 5px;
      margin-right: 4px;
      overflow: hidden;
      position: relative;
    }

    .cover-img {
      width: 48px;
      height: 48px;
      border: 1px solid #f5f5f5;
    }

    .play-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      z-index: 5;
    }

    .over-box {
      font-size: 10px;
      color: #4a4a4a;
      display: flex;
      align-items: flex-end;
    }
  }
}
.recommand-item-box .keyword-box {
  display: inline-block;
  margin-top: 4px;
  margin-right: 22px;
  line-height: 20px;
  max-height: 22px;
  max-width: calc(100% -22px);
  padding: 0 4px;
  background-color: #e7e7e7;
  font-size: 10px;
  color: #737373;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // -webkit-line-clamp: 1;
  // text-overflow: ellipsis;
  // // display: -webkit-box;
  // -webkit-box-orient: vertical;
}
</style>
