<template>
  <div class="recomand-item">
    <div class="recomand-top-box">
      <div class="image-box">
        <van-image
          width="40px"
          height="40px"
          fit="fill"
          radius="4px"
          :src="recommandItem.avatar"
        />
      </div>
      <div class="recommand-item-content">
        <div class="user-name">
          <span v-if="recommandItem.remark">
            {{ recommandItem.remark }}({{ recommandItem.name }})
          </span>
          <span v-else>{{ recommandItem.name }}</span>
          <div class="conversation-time">
            {{ timeformat(recommandItem.msg_time) }}
          </div>
        </div>
        <div class="recommand-content-box">
          <div class="recommand-item-text-box">
            <div :class="['recommand-item-text', isOpen ? '' : 'one-line']">
              {{ recommandItem.content }}
            </div>
          </div>
          <div
            @click="openCommand(recommandItem.work_message_id)"
            :class="['open-btn', isOpen ? 'open-btn-active' : '']"
          >
            <van-icon name="play" size="16px" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="recommand-send-box">
      <RecommandSendItem
        v-for="(item, index) in itemSpeechList"
        :limitNum="3"
        :sendItem="item"
        :mediaList="item.file_arr"
        :customer_userid="customer_userid"
        :chat_id="chat_id"
        :member_userid="member_userid"
        :keyWords="recommandItem.keyword_arr"
        :talkItem="recommandItem"
        :key="index"
      ></RecommandSendItem>
    </div>
  </div>
</template>
<script>
import { Icon, Image as VanImage } from 'vant'
// import RecommandSendItem from './recommandSendItem.vue'
import RecommandSendItem from './recommandSendItem.vue'
import { speechList } from '../../views/tool/marketing/tabPage/test'

import { getRecommandSpeechList } from '../../service/quickSend.service'
import moment from 'moment'
export default {
  name: 'RecommandItem',
  components: {
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    RecommandSendItem: RecommandSendItem
  },
  props: {
    recommandItem: {
      type: Object,
      defualt() {
        return {}
      }
    },
    customer_userid: {
      // 成员id
      type: String,
      default() {
        return ''
      }
    },
    chat_id: {
      ype: String,
      default() {
        return ''
      }
    },
    member_userid: {
      // 外部id(客户id)
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      isOpen: false,
      // customer_userid: '',
      // member_userid: '',
      itemSpeechList: []
      // talking: {}
    }
  },
  mounted() {
    // this.talking = {}
    // console.log(this.recommandItem)
  },
  methods: {
    // 打开推荐回复
    openCommand(work_message_id) {
      getRecommandSpeechList({ work_message_id: work_message_id }).then(
        (res) => {
          if (res.code == 200) {
            this.itemSpeechList = res.data
          }
        }
      )
      // this.itemSpeechList = speechList.data
      // console.log(this.itemSpeechList)
      this.isOpen = !this.isOpen
    },

    // 日期展示规则
    timeformat(date) {
      // 今天最后的时间
      let todayEnd = moment(new Date()).endOf('day')
      let dayLong = 24 * 60 * 60 * 1000
      let dateInt = moment(date).unix() * 1000
      // console.log('-----------------', dateInt)
      let timeDate = ''
      if (todayEnd - dateInt < dayLong) {
        // 今日内
        timeDate = moment(dateInt).format('HH:mm')
      } else if (
        todayEnd - dateInt > dayLong &&
        todayEnd - dateInt < dayLong * 2
      ) {
        // 昨天
        timeDate = '昨天' + moment(dateInt).format('HH:mm')
      } else if (
        todayEnd - dateInt > dayLong * 2 &&
        todayEnd - dateInt < dayLong * 7
      ) {
        let arr = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六'
        ]
        let index = moment(dateInt).day()
        timeDate = arr[index] + ' ' + moment(dateInt).format('HH:mm')
      } else {
        timeDate = moment(dateInt).format('MM-DD')
      }
      // console.log('转化后', timeDate)
      return timeDate
    }
  }
}
</script>

<style lang="less" scoped>
.recomand-item {
  margin-bottom: 1px;
  .recomand-top-box {
    background-color: #fff;
    display: flex;
    padding: 15px;
    .image-box {
      margin-top: 3px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      flex-grow: 0;
    }
  }
  .recommand-item-content {
    box-sizing: border-box;
    flex-grow: 1;
    // max-width: calc(100vw - 110px);
    padding-left: 15px;
    .user-name {
      line-height: 17px;
      font-size: 12px;
      color: #5f5f5f;
      margin-bottom: 3px;
      display: flex;
      justify-content: space-between;

      .conversation-time {
        font-size: 10px;
        line-height: 14px;
        color: #aaaaaa;
        // margin-bottom: 16px;
        white-space: nowrap;
      }
    }
    .recommand-content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .recommand-item-text-box {
        padding: 6px 8px;
        background-color: #f5f5f5;
        border-radius: 5px;
        position: relative;

        &:before {
          width: 0;
          height: 6px;
          content: '';
          border: 6px solid #f5f5f5;
          position: absolute;
          border-color: transparent #f5f5f5 transparent transparent;
          top: 10px;
          left: -12px;
        }
      }
      .recommand-item-text {
        line-height: 20px;
        font-size: 14px;
        color: #171717;
        word-break: break-all;
      }
      .one-line {
        overflow: hidden;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      // 下拉按钮
      .open-btn {
        width: 32px;
        height: 32px;
        margin-left: 15px;
        // margin-right: 3px;
        transform: rotate(90deg);
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        .van-icon {
          width: 16px;
          height: 16px;
        }
      }

      .open-btn-active {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
    }
    // .all-line {
    //   overflow: hidden;
    //   -webkit-line-clamp: 3;
    //   text-overflow: ellipsis;
    //   display: -webkit-box;
    //   -webkit-box-orient: vertical;
    // }
  }
  // .opration-box {
  //   flex-grow: 0;
  //   margin-left: 15px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;

  //   .conversation-time {
  //     font-size: 10px;
  //     line-height: 14px;
  //     color: #aaaaaa;
  //     margin-bottom: 16px;
  //     white-space: nowrap;
  //   }
  //   .open-btn {
  //     width: 16px;
  //     height: 16px;
  //     margin-right: 3px;
  //     transform: rotate(90deg);
  //     transition: all 0.3s;
  //   }

  //   .open-btn-active {
  //     transform: rotate(-90deg);
  //     transition: all 0.3s;
  //   }
  // }

  .recommand-send-box {
    padding: 1px 19px 19px 50px;
  }
}
</style>
