<template>
  <div class="marketing-page">
    <div class="marketing-page-content">
      <van-tabs
        v-model:active="activeName"
        title-active-color="#fff"
        title-inactive-color="#1773FA"
        background="#fff"
        color="#1773FA"
        line-width="48px"
        @change="onClickTab"
        sticky
        type="card"
      >
        <van-tab title="任务1" name="task" title-class="task-tab-title">
          <!-- :badge="unstartNum === 0 ? null : unstartNum" -->
          <template #title>
            <div class="task-tab-title-box">
              任务
              <div v-if="unstartNum" class="unstartNum-box">
                <span class="unstartNum-content">{{ unstartNum }}</span>
              </div>
            </div>
          </template>
          <TaskList
            v-if="activeName === 'task'"
            @prsendSuccess="itemSended"
            :customer_userid="taskparams.customer_userid"
          ></TaskList>
        </van-tab>
        <van-tab title="推荐回复" name="ai_replay" title-class="task-tab-title">
          <!-- <AiReplay v-if="activeName === 'ai_replay'"></AiReplay> -->
          <RecomandReview v-if="activeName === 'ai_replay'"></RecomandReview>
        </van-tab>
        <van-tab
          title="标签营销"
          name="suggestions"
          title-class="task-tab-title"
        >
          <LabelMarketing v-if="activeName === 'suggestions'"></LabelMarketing>
          <!-- <Suggestions v-if="activeName === 'suggestions'"></Suggestions> -->
        </van-tab>
        <van-tab title="知识库" name="knowledge" title-class="task-tab-title">
          <KnowledgeBase
            v-if="activeName === 'knowledge'"
            :customer_userid="taskparams.customer_userid"
            :chat_id="taskparams.chat_id"
          ></KnowledgeBase>
        </van-tab>
        <van-tab
          title="我的话术"
          name="personal_speech"
          title-class="task-tab-title"
        >
          <PersonalSpeech
            v-if="activeName === 'personal_speech'"
            :customer_userid="taskparams.customer_userid"
            :chat_id="taskparams.chat_id"
          ></PersonalSpeech>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import {
  Tabs,
  Tab,
  PullRefresh,
  List,
  Collapse,
  CollapseItem,
  // Cell,
  // CellGroup,
  // Toast,
  Badge
} from 'vant'
// import MarketingTaskItem from './MarketingTaskItem.vue'
import TaskList from './tabPage/taskList.vue'
// import Suggestions from './tabPage/suggestions.vue'
// import AiReplay from './tabPage/aiReply.vue'
import KnowledgeBase from './tabPage/knowledge.vue'
import PersonalSpeech from './tabPage/personalSpeech.vue'
import RecomandReview from './tabPage/recommand.vue'
import LabelMarketing from './tabPage/labelMaketing.vue'
import {
  getMarketingTaskList,
  getTaskcount
} from '../../../service/quickSend.service.js'
import {
  getCurExternalContact,
  getCurExternalChat,
  getCurExternalContactPolling,
  getCurExternalChatPolling
} from '../../../utils/nwechat.js'
export default {
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    // [Cell.name]: Cell,
    // [CellGroup.name]: CellGroup,
    // [Toast.name]: Toast,
    [Badge.name]: Badge,
    // MarketingTaskItem: MarketingTaskItem,
    // Suggestions: Suggestions,
    // AiReplay: AiReplay,
    KnowledgeBase: KnowledgeBase,
    PersonalSpeech: PersonalSpeech,
    RecomandReview: RecomandReview,
    LabelMarketing: LabelMarketing,
    TaskList: TaskList
  },
  data() {
    return {
      activeName: 'task',
      loading: false,
      taskList: [],
      count: 0,
      finished: false,
      refreshing: false,
      taskparams: {
        member_userid: '',
        customer_userid: '',
        chat_id: '',
        page: 1,
        page_size: 20
      },
      CustomerId: '',
      unstartNum: 0,
      requestStatus: null,
      pollingIndex: 0
    }
  },
  mounted() {
    // 改变标题：
    // console.log('luyou---------------', this.$route)
    // document.title = this.$route.meta.title
    this.activeName = this.$route.query.acId || 'task'
    this.init()
    // 初始化定义页面标题
    // document.title = '任务'
    let nameObj = {
      task: '任务',
      ai_replay: '推荐回复',
      suggestions: '标签营销',
      knowledge: '知识库',
      personal_speech: '个人话术'
    }

    // 数据埋点，进入页面埋点
    window._paq.push(['setDocumentTitle', nameObj[this.activeName]])
  },
  beforeDestroy() {
    // 清除定时器
    // clearInterval(this.requestStatus)
  },
  methods: {
    // onNext() {},
    // 初始化页面
    async init() {
      // 成员id
      let userInfo = JSON.parse(localStorage.getItem('vuex'))
      this.taskparams.member_userid = userInfo.user.info.qw_userid
      // this.taskparams.member_userid = 'blue'

      // 轮询查询用户id
      try {
        this.taskparams.customer_userid = await getCurExternalContactPolling(
          1,
          2
        )
      } catch (e) {
        console.log(e)
      }
      if (!this.taskparams.customer_userid) {
        // 轮询群id
        try {
          this.taskparams.chat_id = await getCurExternalChatPolling(1, 2)
        } catch (e) {
          console.log(e)
        }
      }

      this.getUnstartCount()

      // 轮询请求客户id
      // this.requestStatus = setInterval(() => {
      //   if (
      //     this.taskparams.customer_userid ||
      //     this.taskparams.chat_id ||
      //     this.pollingIndex > 4
      //   ) {
      //     console.log('任务数量轮询次数-最终-count', this.pollingIndex)
      //     clearInterval(this.requestStatus)
      //     return
      //   }
      //   // console.log('现在是第', this.pollingIndex)
      //   console.log('任务数量轮询次数-count', this.pollingIndex)
      //   // 本地调试
      //   this.pollingIndex++
      //   // this.taskparams.customer_userid = 'wm1DICCQAAaO7NrD5ttDF1PpUv5uQqIA'
      //   // this.getUnstartCount()

      //   getCurExternalContact()
      //     .then((res) => {
      //       this.taskparams.customer_userid = res
      //       this.getUnstartCount()
      //     })
      //     .catch(() => {
      //       this.taskparams.customer_userid = ''
      //     })
      //   getCurExternalChat()
      //     .then((res) => {
      //       // console.log('resres群', res)
      //       this.taskparams.chat_id = res
      //       this.getUnstartCount()
      //     })
      //     .catch(() => {
      //       this.taskparams.chat_id = ''
      //     })
      // }, 300)
    },

    async onClickTab() {
      let obj = {
        task: 'marketing-suggestions',
        ai_replay: 'marketing-ai_replay',
        suggestions: 'marketing-label',
        knowledge: 'marketing-knowledge',
        personal_speech: 'marketing-personal_speech'
      }

      if (this.activeName === 'task' && this.taskparams.customer_userid) {
        this.getUnstartCount()
      }
      console.log(this.$router)
      this.$router.push({
        path: obj[this.activeName],
        query: { acId: this.activeName }
      })
      // this.$route.meta.title = obj[this.activeName]
      // document.title = obj[this.activeName]
    },

    // 获取未执行任务数量
    async getUnstartCount() {
      // 没有获取到客户id的不进行下面的接口请求
      clearInterval(this.requestStatus)
      if (!this.taskparams.customer_userid && !this.taskparams.chat_id) {
        return
      }
      const { data } = await getTaskcount({
        member_userid: this.taskparams.member_userid,
        customer_userid: this.taskparams.customer_userid || '',
        chat_id: this.taskparams.chat_id || ''
      })
      this.unstartNum = data.count || null
    },

    // 子组件任务条目发送成功传值
    itemSended(index) {
      if (this.unstartNum >= 1) {
        this.unstartNum -= 1
      }
      // this.taskList.splice(index, 1)
      // if (this.taskList.length == 0) {
      //   this.count = 0
      //   this.finished = true
      // }
    },

    // 超过18, 前 8 后 8 中间...
    formatTitle(txt) {
      if (txt.length < 19) {
        return txt
      } else {
        let len = txt.length
        let start = txt.substring(0, 8)
        let end = txt.substring(len - 8, len)
        return `${start}...${end}`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.marketing-page {
  background-color: #f5f5f5;
  // .task-tab-title {
  //   // color: #000;
  //   font-size: 12px;
  // }
  // .marketing-page-content {
  //   padding-top: 15px;
  //   background-color: #fff;
  // }
}

.image {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  .img {
    width: 100px;
    height: 100px;
  }
}
.cell-marketing :deep(.van-cell__title) {
  width: 100%;
}

// .no-data {
//   margin-top: 20px;
//   text-align: center;
//   color: #aaa;
// }
</style>
<style lang="less">
// 覆盖vant标签页样式
.marketing-page {
  // .van-pull-refresh {
  //   min-height: calc(100vh - 75px);
  // }
  .van-tabs__wrap {
    height: 65px;
    overflow: visible;
    // margin-bottom: 7px;
    padding-top: 15px;
    background-color: #fff;
  }
  .van-tabs__nav--card {
    height: 36px;
    border-radius: 5px;
  }
  .van-tabs__nav--card .van-tab {
    border: 0;
  }
  .van-tab__text--ellipsis {
    font-size: 12px;
    display: block;
    margin-top: 1px;
    overflow: visible;
  }

  .task-tab-title {
    position: relative;
    .unstartNum-box {
      // position: absolute;
      // left: -8px;
      // right: -8px;
      // display: inline-block;
      // box-sizing: border-box;
      // min-width: 16px;
      // padding: 0 3px;
      // line-height: 1.2;
      // text-align: center;
      position: absolute;
      top: -9px;
      right: 4px;
      // padding: 2px;
      border: 1px solid #1773fa;
      height: 18px;
      border-radius: 99px;
      .unstartNum-content {
        position: relative;
        z-index: 6;
        display: block;
        box-sizing: border-box;
        min-width: 16px;
        padding: 0 3px;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        // line-height: 1.2;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background-color: #ee0a24;
        border: 1px solid #fff;
        border-radius: 99px;
      }

      &:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 12px;
        background-color: #fff;
        top: -5px;
        left: -5px;
        z-index: 4;
      }
    }
  }
  // 调整bant bage位置
  .van-badge--fixed {
    top: -8px;
    right: -8px;
  }
  .van-tabs__nav--card .van-tab--disabled {
    color: #c8c9cc !important;
  }

  // .van-tab__text--ellipsis
}
</style>
