<template>
  <div class="personal-speech-page">
    <van-sticky :offset-top="65">
      <div v-if="!isOpenSetting" class="imitate-search-box">
        <div class="imitate-input" @click="onSearch">
          <van-icon
            :name="require('@/assets/img/common/search.svg')"
            color="#999"
            size="16"
          />
          <span class="imitate-placehodor">搜索</span>
        </div>
        <div class="setting-icon">
          <van-icon
            :name="require('@/assets/img/quickSend/setting.svg')"
            size="20"
            @click="openSetting"
          />
        </div>
      </div>
    </van-sticky>
    <div class="speech-list-box">
      <van-pull-refresh
        v-if="!isOpenSetting"
        v-model="refreshing"
        @refresh="onRefresh"
        :head-height="36"
        :success-duration="1300"
      >
        <template #loading>
          <div class="refresh-box">
            <img
              class="loading-iocn"
              src="../../../../assets/img/quickSend/loading.svg"
              alt="加载"
            />
            <span>加载中</span>
          </div>
        </template>
        <template #success>
          <div class="animate-refresh">
            <span>最新时间</span>
            &nbsp;
            <span>{{ nowDate() }}</span>
          </div>
        </template>
        <div :class="['speech-list', isOpenSetting ? '' : 'plr15']">
          <van-list
            v-if="list.length || refreshing"
            v-model:loading="loading"
            v-model:error="error"
            :finished="finished"
            :immediate-check="false"
            error-text="请求失败，点击重新加载"
            :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
            @load="onload"
          >
            <div>
              <sendListItem
                v-for="(item, index) in list"
                :sendItem="item"
                pageType="speech"
                :customer_userid="customer_userid"
                :chat_id="chat_id"
                :member_userid="member_userid"
                :index="index"
                :key="index"
                cutColor="#f5f5f5"
              ></sendListItem>
            </div>
          </van-list>
          <div v-else class="no-data-page">
            <div class="no-data-content">
              <img
                src="@/assets/img/quickSend/speech_nodata.svg"
                alt="暂无数据"
              />
              <p class="no-data-hint">暂无话术</p>
            </div>
          </div>
        </div>
      </van-pull-refresh>
      <div class="manege-box" v-else>
        <draggable
          v-model="list"
          group="type"
          @start="drag = true"
          @end="drag = false"
          :animation="500"
          ghost-class="manege-item-move"
          item-key="sale_script_id"
          :delay="200"
        >
          <template #item="{ element, index: sIndex }">
            <div class="manege-item">
              <div
                class="manege-del-img"
                @click.stop="deleteSpeech(element, sIndex)"
              >
                <van-icon
                  :name="require('@/assets/img/quickSend/delete_icon.svg')"
                  size="16"
                />
              </div>
              <div class="manege-item-conten">
                <p class="manege-item-text">
                  {{ element.content }}
                </p>
                <div class="edit-btn" @click.stop="goEdit(element)">
                  <van-icon
                    :name="require('@/assets/img/quickSend/edit_icon.svg')"
                    size="14px"
                  />
                </div>
              </div>
              <div class="manege-item-move">
                <van-icon
                  :name="require('@/assets/img/quickSend/menu_move.svg')"
                  size="20"
                />
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <!-- 添加、管理按钮 -->
    <div class="bottom-btn-box">
      <div v-if="!isOpenSetting" class="send-btn" @click="onAddition">
        <img
          class="send-icon"
          src="@/assets/img/quickSend/addition.svg"
          alt="分享"
        />
        <div class="send-text">添加话术</div>
      </div>
      <div v-else class="send-btn" @click="onManeged">
        <div class="send-text">完成</div>
      </div>
    </div>
    <!-- 删除提示 -->
    <van-overlay :show="showDeleteHint" @click="showDeleteHint = false">
      <div class="hint-wrapper" @click.stop>
        <div class="commit-box">
          <p class="hint-text">是否删除当前话术？</p>
          <div class="btn-box">
            <div class="hint-btn cancel" @click.stop="showDeleteHint = false">
              取消
            </div>
            <div class="hint-btn danger" @click.stop="confirmDeleteSpeech">
              确认
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { PullRefresh, List, Icon, Overlay, Toast, Sticky } from 'vant'
import sendListItem from '../../../../components/quickSend/sendListItem.vue'
import {
  getSpeechList,
  changeSpeechsSort,
  deletePersonalSpeech
} from '../../../../service/quickSend.service'
import { getCurExternalContact } from '../../../../utils/nwechat.js'
import moment from 'moment'
import draggable from 'vuedraggable'
import { personalSpeech } from '../tabPage/test'
export default {
  name: 'personalSpeech',
  components: {
    // [Search.name]: Search,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Overlay.name]: Overlay,
    [Sticky.name]: Sticky,
    sendListItem: sendListItem,
    draggable
  },
  props: {
    chat_id: {
      type: [String, Number],
      default: ''
    },
    customer_userid: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      params: {
        page_num: 1,
        page_size: 20
      },
      list: [],
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      value: '',
      // customer_userid: '',
      member_userid: '',
      isOpenSetting: false,
      drag: false,
      showDeleteHint: false,
      deleteItem: {}
    }
  },
  mounted() {
    // this.onload()

    this.isEditBack()
    this.initId()
  },
  methods: {
    // 加载列表
    async onload() {
      if (this.list.length > 0) {
        this.params.page_num += 1
      }
      try {
        this.loading = true
        const { data, code } = await getSpeechList(this.params)
        // let data = personalSpeech.data
        // let code = 200

        if (code !== 200) {
          this.error = true
          this.count = 0
          throw code
        }

        for (let i = 0; i < data.list.length; i++) {
          data.list[i].type = 1
        }

        this.count = data.total_num || 0
        this.list = this.list.concat(data.list)
        this.loading = false

        // 翻到最后一页
        if (
          this.list.length < this.params.page_size ||
          this.count === this.list.length
        ) {
          this.finished = true
        }
      } catch (error) {
        this.error = true
        this.finished = true
        console.log(error)
      } finally {
        this.loading = false
        return Promise.resolve(true)
        // return Promise.resolve(true)
      }
    },

    // 判断是否从编辑页面返回
    async isEditBack() {
      if (this.$route.query.editedId) {
        // this.openSetting()
        await this.onload()
        this.openSetting()
      } else {
        this.onload()
      }
    },

    // 获取客户id 和成员id
    async initId() {
      let userInfo = JSON.parse(localStorage.getItem('vuex'))
      this.member_userid = userInfo.user.info.qw_userid
      // 客户id
      // try {
      //   let res = await getCurExternalContact()
      //   this.customer_userid = res
      // } catch (e) {
      //   console.log(e)
      // }
    },

    // 下拉刷新
    async onRefresh() {
      this.list = []
      this.refreshing = true
      this.params.page_num = 1
      await this.onload()
      this.refreshing = false
    },

    // 打开管理
    openSetting() {
      if (this.list.length == 0) {
        Toast.fail('暂无话术可以管理')
        return
      }
      this.isOpenSetting = true
    },

    // 完成管理
    async onManeged() {
      // let sale_script_list = []
      // this.list.forEach((item, index) => {
      //   sale_script_list.push({
      //     sale_script_id: item.sale_script_id,
      //     sort: index
      //   })
      // })
      // await changeSpeechsSort({ sale_script_list })
      if (!this.list[0]) {
        this.isOpenSetting = false
        return
      }

      await this.updataSort()
      this.isOpenSetting = false
    },

    // 刷新排序
    async updataSort() {
      let sale_script_list = []
      this.list.forEach((item, index) => {
        sale_script_list.push({
          sale_script_id: item.sale_script_id,
          sort: index
        })
      })
      // let sale_script_list = arr.reverse()
      await changeSpeechsSort({ sale_script_list })
      // return Promise().resolve({ refreshd: true })
      return Promise.resolve({ refreshd: true })
    },
    // 前往编辑话术
    async goEdit(item) {
      await this.updataSort()
      sessionStorage.setItem('editSpeech', JSON.stringify(item))
      this.$router.push({
        path: '/personalspeech-edit',
        query: { pageType: 'edit' }
      })
    },

    deleteSpeech(item, index) {
      // console.log('删除话术', item, index)
      this.deleteItem = item
      this.deleteItem.index = index
      this.showDeleteHint = true
    },

    async confirmDeleteSpeech() {
      console.log('删除元素', this.deleteItem)
      await deletePersonalSpeech({
        sale_script_id: this.deleteItem.sale_script_id
      })
      this.list.splice(this.deleteItem.index, 1)
      this.showDeleteHint = false
    },

    // 跳转搜索页
    onSearch() {
      // this.$router.push('/personalspeech-search')
      this.$router.push({
        path: '/personalspeech-search',
        query: {
          chat_id: this.chat_id || '',
          customer_userid: this.customer_userid || ''
        }
      })
    },

    onAddition() {
      this.$router.push({
        path: '/personalspeech-edit',
        query: {
          pageType: 'add'
        }
      })
    },
    // 刷新条时间
    nowDate() {
      return moment(new Date()).format('HH:mm:ss')
    }
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/style/bottom_btn.less');
// 模拟输入框
@import url('~@/assets/style/imitate_search.less');
.personal-speech-page {
  min-height: calc(100vh - 66px);
  background-color: #f5f5f5;

  // // 模拟搜索框
  // .imitate-search-box {
  //   padding: 12px 16px;
  //   padding-top: 4px;
  //   background-color: #fff;
  //   display: flex;
  //   align-items: center;

  //   .imitate-input {
  //     line-height: 32px;
  //     background-color: #f5f5f5;
  //     font-size: 12px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     color: #999999;
  //     border-radius: 5px;
  //     cursor: pointer;

  //     flex-grow: 1;
  //     .imitate-placehodor {
  //       margin-left: 8px;
  //     }
  //   }

  //   // 搜索框的齿轮图标
  //   .setting-icon {
  //     display: flex;
  //     align-items: center;
  //     padding-left: 20px;
  //     padding-right: 6px;
  //   }
  // }

  .speech-list-box {
    height: calc(100vh -113px);
    overflow-y: scroll;

    .speech-list {
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 80px;
      // height: calc(100vh -113px);
      // overflow-y: scroll;
    }
  }

  // 管理状态下展示
  .manege-box {
    padding-top: 8px;
  }
  .manege-item {
    display: flex;
    background-color: #fff;
    align-items: center;

    .manege-del-img {
      box-sizing: content-box;
      flex-grow: 0;
      width: 16px;
      height: 16px;
      min-width: 16px;
      padding: 17px 15px 17px 18px;
    }

    .manege-item-conten {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      // border-bottom: 1px;

      border-bottom: 1px solid #f5f5f5;

      .edit-btn {
        // flex-grow: 0;
        // box-sizing: content-box;
        // width: 14px;
        // height: 14px;
        // padding: 15px;

        box-sizing: content-box;
        flex-grow: 0;
        width: 16px;
        height: 16px;
        min-width: 16px;
        padding: 15px;
      }
    }
    .manege-item-text {
      flex-grow: 1;
      line-height: 17px;
      font-size: 12px;
      color: #4a4a4a;
      overflow: hidden;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin: 17px 0 17px 17px;
    }
    // .manege-item + .manege-item {
    //   .manege-item-conten {
    //     border-top: 1ox solid #f5f5f5;
    //   }
    // }

    .manege-item-move {
      flex-grow: 0;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      border-left: 1px solid #f5f5f5;
      min-width: 51px;
    }
  }
  & + & .manege-item-conten {
    border-top: 1ox solid #f5f5f5;
  }

  // .no-data-page {
  //   margin-top: 1px;
  //   height: calc(100vh - 123px);
  //   background-color: #f5f5f5;
  //   display: flex;
  //   justify-content: center;
  //   // padding-top: 150px;
  //   align-items: center;
  //   .no-data-content {
  //     margin-top: -123px;
  //     img {
  //       width: 110px;
  //       height: 118px;
  //     }
  //     .no-data-hint {
  //       margin-top: 13px;
  //       font-size: 14px;
  //       line-height: 20px;
  //       color: #909399;
  //       text-align: center;
  //     }
  //   }
  // }

  // 删除提示
  .van-overlay {
    z-index: 100;
  }
  .hint-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .commit-box {
      // flex-grow: 1;
      width: 285px;
      padding: 35px 15px 24px 15px;
      background-color: #fff;
      border-radius: 5px;
    }

    .hint-text {
      line-height: 20px;
      font-size: 14px;
      text-align: center;
      padding-bottom: 32px;
      color: #4a4a4a;
    }

    .btn-box {
      display: flex;
    }

    .hint-btn {
      flex: 1;
      line-height: 44px;
      text-align: center;
      font-size: 14px;
      border-radius: 5px;
    }
    .hint-btn + .hint-btn {
      margin-left: 15px;
    }
    .cancel {
      background-color: #f5f7fa;
      color: #999999;
    }
    .danger {
      background-color: #f56c6c;
      color: #ffffff;
    }
  }
}
</style>

<style lang="less">
.personal-speech-page {
  .van-search .van-search__action {
    padding: 0 20px;
  }
  .van-list .van-list__finished-text {
    line-height: 20px;
  }
}
</style>
